<template>
  <div>
    <b-card>
      <div slot="header">
        <strong>기간매출통계 <small>발란몰 주문중 교환, 취소, 반품을 제외한 주문입니다. 주문일(결제이후) 기준입니다.</small> </strong>
      </div>
      <b-form>
        <div><small>주문일</small></div>
        <date-from-to :from.sync="form.orderDateFrom" :to.sync="form.orderDateTo" v-bind="{twoDays: true, absMonth: 12, year: 5}" @enter="list()">
        </date-from-to>

        <div class="mb-2"></div>

        <b-row class="mb-2">
          <b-col>
            <brand-preset class="mb-2" v-model="form.brand" :hideDisabled="true"></brand-preset>
          </b-col>
          <b-col>
            <category-preset class="mb-2" v-model="form.category"></category-preset>
<!--            <b-form-checkbox v-model="form.exactCategory">하위카테고리를 포함하지 않습니다</b-form-checkbox>-->
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="2">
            <div><small>정렬순서</small></div>
            <b-form-radio-group class="col-form-label" v-model="form.orderBy" :options="[
                {text: '판매금액', value: 'amount'},
                {text: '거래액', value: 'sale_price'},
                {text: '실거래액', value: 'real_sale_price'},
                {text: '최대혜택주문액', value: 'member_price'},
                {text: '판매수량', value: 'cnt'},
                {text: '주문수', value: 'cnt'},
                {text: '실주문수', value: 'real_cnt'},
              ]"></b-form-radio-group>
          </b-col>
          <b-col cols="12" lg="2">
            <b-row>
              <b-col>
                <div><small>Top 그룹수</small></div>
                <b-input v-model.number="form.groupLimit"></b-input>
              </b-col>
              <b-col>
                <div><small>Sub 그룹수</small></div>
                <b-input v-model.number="form.subGroupLimit"></b-input>
              </b-col>
              <b-col>
                <div><small>Top 상품수</small></div>
                <b-input v-model.number="form.goodsLimit"></b-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" lg="3">
            <div><small>그룹순서(드래그 해서 지정해주세요)</small></div>
            <draggable v-model="form.groupBy" @start="drag=true" @end="drag=false">
              <template v-for="e in form.groupBy">
                <b-button :variant="groupColorMap[e]" class="btn-pill m-1">{{ e === 'category' ? groupNameMap[form.categoryType] : groupNameMap[e] }}</b-button>
              </template>
            </draggable>
            <b-form-checkbox v-model="form.allGroup">최상위 그룹을 전체 주문 대상으로 합니다</b-form-checkbox>
          </b-col>
          <b-col cols="12" lg="3">
            <div><small>카테고리 종류</small></div>
            <b-form-radio-group class="col-form-label" v-model="form.categoryType" @change="setCategoryType" :options="[
              {text: '1차 카테고리', value: 'genderCategory'},
              {text: '2차 카테고리', value: 'majorCategory'},
              {text: '3차 카테고리', value: 'minorCategory'},
              {text: '4차 카테고리', value: 'smallerCategory'},
              {text: '최종 카테고리(3~5차)', value: 'category'},
            ]"></b-form-radio-group>
          </b-col>
        </b-row>
      </b-form>
      <b-button class="m-1" variant="primary" @click="list">조회</b-button>
    </b-card>

    <div class="d-flex align-items-center">
      <div class="ml-auto">
        <b-button class="m-1" variant="success" @click="down('xlsx')">XLSX</b-button>
        <b-button class="m-1" variant="success" @click="down('txt')">Text</b-button>
      </div>
    </div>
    <!--<c-table :table-data="items" :fields="fields" :perPage.sync="perPage" :isBusy="isBusy" :caption="items.length + ' 개'"></c-table>-->
    <b-card v-for="(A,i) in items">
      <h4 v-if="form.idxGroup === 0">
        <span :title="A.id" class="pointer" @click="$utils.copyAlert(A.id)">
          {{ groupNameMap[lastForm.groupBy[0 - form.idxGroup]] }} {{ i + 1 }}위 : {{ A.name }}
        </span>
      </h4>
      <h4 v-else>{{ A.name }}</h4>
      <b-row>
        <b-col cols="1">
          <b-badge variant="success">판매금액</b-badge>
          {{ $utils.comma(A.amount) }}<br/>
          <b-badge variant="primary">판매수량</b-badge>
          {{ $utils.comma(A.qty) }}
        </b-col>
        <b-col cols="1">
          <b-badge variant="success">거래액</b-badge>
          {{ $utils.comma(A.sale_price) }}<br/>
          <b-badge variant="primary">주문수</b-badge>
          {{ $utils.comma(A.cnt) }}
        </b-col>
        <b-col cols="2">
          <b-badge variant="success">실거래액</b-badge>
          {{ $utils.comma(A.real_sale_price) }}<br/>
          <b-badge variant="primary">최대혜택주문액</b-badge>
          {{ $utils.comma(A.member_price) }}<br/>
          <b-badge variant="primary">실주문수</b-badge>
          {{ $utils.comma(A.real_cnt) }}
        </b-col>
      </b-row>

      <hr/>
      <h5>Top {{ form.subGroupLimit }} {{ groupNameMap[lastForm.groupBy[1 - form.idxGroup]] }}</h5>
      <b-row class="mt-3">
        <b-col cols="2" v-for="(B, j) in A.groupB.slice(0, form.subGroupLimit)" :key="j">
          <h6>
            <span :title="B.id" class="pointer" @click="$utils.copyAlert(B.id)">{{ B.name }}</span>
          </h6>
          <b-badge variant="success">(OLD)판매금액</b-badge>
          {{ $utils.comma(A.amount) }}<br/>
          <b-badge variant="primary">(OLD)판매수량</b-badge>
          {{ $utils.comma(A.qty) }}<br/>
          <b-badge variant="success">거래액</b-badge>
          {{ $utils.comma(B.sale_price) }}<br/>
          <b-badge variant="primary">주문수</b-badge>
          {{ $utils.comma(B.cnt) }}<br/>
          <b-badge variant="success">실거래액</b-badge>
          {{ $utils.comma(A.real_sale_price) }}<br/>
          <b-badge variant="primary">최대혜택주문액</b-badge>
          {{ $utils.comma(A.member_price) }}<br/>
          <b-badge variant="primary">실주문수</b-badge>
          {{ $utils.comma(A.real_cnt) }}<br/>
          <b-badge variant="dark">주요 {{ groupNameMap[lastForm.groupBy[2 - form.idxGroup]] }}</b-badge>
          <br/>
          <template v-if="B.groupC">
            <b-badge class="mb-1 mr-1 pointer" variant="secondary" :title="C.id" v-for="(C, k) in B.groupC.slice(0, 5)" @click="$utils.copyAlert(C.id)" :key="k">{{
                C.name
              }}
            </b-badge>
          </template>
        </b-col>
      </b-row>
      <hr/>
      <h5>Top {{ form.goodsLimit }} 상품</h5>
      <b-row v-if="form.goodsLimit" class="mt-3">
        <b-col>
          <div class="d-inline-block mr-1 text-center" v-for="e in A.goods">
            <img :src="e.img" style="height:105px" :title="e.goods_nm"/><br/>
            <a :href="`/#/goods/${e._id}`" target="_blank" class="badge badge-primary">{{ e._id }}</a><br/>
            <template v-if="lastForm.groupBy[1-form.idxGroup] === 'shop_id'">
              <b-badge variant="success">{{ e.shop_id }}. {{ shopMap[e.shop_id] ? shopMap[e.shop_id].boutique : "SHOP 정보 없음" }}</b-badge>
              <br/>
            </template>
            <template v-if="lastForm.groupBy[1-form.idxGroup] === 'brand_no'">
              <b-badge variant="warning">{{ brandMap[e.brand_no] ? brandMap[e.brand_no].brand_nm : `brand_no : ${e.brand_no}` }}</b-badge>
              <br/>
            </template>
            <b-badge variant="light">단가: {{ $utils.comma(e.price) }} 원</b-badge>
            <br/>
            <b-badge variant="light">{{ $utils.comma(e.top_qty) }} 개</b-badge>
            <br/>
            <b-badge variant="light" :title="`개당 가격 ${$utils.comma(e.price)} 원`">{{ $utils.comma(e.top_amount) }} 원</b-badge>
            <br/>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="text-center" v-if="isBusy">
      <b-spinner variant="primary"></b-spinner>
    </b-card>
    <b-card class="text-center" v-else-if="items.length === 0">
      결과가 없습니다
    </b-card>
  </div>
</template>

<script>
import dateInput from '@/views/modules/DateInput.vue'
import * as moment from 'moment-timezone';
import draggable from 'vuedraggable'
import {down} from '@/shared/impexp'

export default {
  name: 'OrderStat',
  title: '기간매출통계',
  components: {dateInput, draggable},
  data() {
    return {
      months: [],
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      form: {
        orderDateFrom: moment().tz("Asia/Seoul").subtract(1, 'day').format('YYYY-MM-DD'),
        orderDateTo: moment().tz("Asia/Seoul").format('YYYY-MM-DD'),
        shop: [],
        category: [],
        brand: [],
        categoryType: 'category',
        groupLimit: 20,
        subGroupLimit: 6,
        goodsLimit: 15,
        orderBy: 'amount',
        goods_no: 'warning',
        price_low: 'success',
        stock_total: 'secondary',
        groupBy: ['brand_no', 'category', 'shop_id'],
        idxGroup: 0,
        exactCategory: false,
        allGroup: false,
        includeCancel: false,
      },
      lastForm: {},
      groupNameMap: {
        brand_no: '브랜드',
        shop_id: '파트너',
        genderCategory: '1차 카테고리',
        majorCategory: '2차 카테고리',
        minorCategory: '3차 카테고리',
        smallerCategory: '4차 카테고리',
        category: '최종 카테고리(3~5차)',

      },
      groupColorMap: {
        brand_no: 'warning',
        shop_id: 'success',
        genderCategory: 'secondary',
        majorCategory: 'secondary',
        minorCategory: 'secondary',
        smallerCategory: 'secondary',
        category: 'secondary',
        goodsType: 'primary',
      },
      detailCond: {},
      brand_no: '',
      goods_no: '',
      items: [],
      goods: [],
      perPage: 1000,
      isBusy: false,
      isDetailBusy: false,
      modal: false,
    }
  },
  async created() {
    let monSt = moment().startOf('month');
    while (monSt >= moment().add(-12, 'month')) {
      this.months.push(monSt.format('YYYY-MM-DD'));
      monSt.add(-1, 'month');
    }
    let meta = await this.$api.getMeta();
    if (!meta) return;
    this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);
    meta.shop.forEach(s => {
      s.value = s.boutique;
      s.label = `${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });
    this.brand = meta.brand.sort((a, b) => a.brand_nm.localeCompare(b.brand_nm));
    this.brand.forEach(b => {
      b.value = b.brand_no;
      b.label = `${b.brand_nm}(${b.brand_nm_kr})`;
      this.brandMap[b.brand_no] = b;
    });
    this.category = meta.category.sort((a, b) => a.category.localeCompare(b.category));
    this.category.forEach(c => {
      this.categoryMap[c.category] = c;
    });


    this.list();
  },
  methods: {
    setCategoryType(type) {
      this.form.groupBy.forEach((e, i) => {
        if (~e.indexOf('tegory')) {
          this.form.groupBy[i] = type;
        }
      });
    },
    async list() {
      let form = this.form;
      this.lastForm = this.$utils.clone(form);

      this.items.splice(0, this.items.length);
      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      let brand = form.brand.map(e => e.value);
      let category = form.category.map(e => e.value);

      this.isBusy = true;
      let j = await this.$api.postJson('/order/godoOrderStat', {form: {...this.form, shop, brand, category}});
      this.isBusy = false;
      if (!j) return;

      j.list.forEach(e => { // xls 다운로드용 데이터를 정비한다.
        e['판매일'] = e.date;
        e['판매금액'] = e.amount;
        e['판매수량'] = e.qty;
        e['거래액'] = e.sale_price;
        e['실거래액'] = e.real_sale_price;
        e['최대혜택가기준실거래액'] = e.member_price;
        e['주문수'] = e.cnt;
        e['실주문수'] = e.real_cnt;
        e['상품번호'] = e.goods_no;
        e['상품명'] = e.goods_nm;
        e['브랜드'] = this.brandMap[e.brand_no] ? this.brandMap[e.brand_no].brand_nm : e.brand_no; // brand_no == 0
        e['성별'] = {'009': 'Women', '010': 'Men'}[e.genderCategory] || '없음';
        e['2DEPTH'] = (this.categoryMap[e.majorCategory] || {}).category_nm || '';
        e['3DEPTH'] = (this.categoryMap[e.minorCategory] || {}).category_nm || '';
        if (e.category.length >= 12)
          e['4DEPTH'] = (this.categoryMap[e.smallerCategory] || {}).category_nm || '';
        if (e.category.length > 12)
          e['5DEPTH'] = (this.categoryMap[e.category] || {}).category_nm || '';
        e['Shop ID'] = e.shop_id;
        e['구분'] = this.shopMap[e.shop_id].shop_type;
        e['상품링크'] = `${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${e.goods_no}`;
        e['이미지'] = e.img;
      });
      j.list.sort((a, b) => b.date.localeCompare(a.date) || (b.amount - a.amount));
      this.goods = j.list;

      this.form.idxGroup = this.form.allGroup ? 1 : 0;

      let sortKey = this.form.orderBy;
      this.items = Object.values(j.groupMap).sort((a, b) => b[sortKey] - a[sortKey]).slice(0, this.form.groupLimit); // 성능상의 이슈도 있고 해서 Top 100 만 뽑는다.

      this.items.forEach(A => {
        this.setName(A, this.form.idxGroup === 0 ? this.form.groupBy[0] : 'all');
        let groupB = Object.values(A.groupB);
        A.groupB = groupB.sort((a, b) => b[sortKey] - a[sortKey]);
        A.groupB.forEach(B => {
          this.setName(B, this.form.groupBy[1 - this.form.idxGroup]);
          let groupC = Object.values(B.groupC);
          B.groupC = groupC.sort((a, b) => b[sortKey] - a[sortKey]);
          B.groupC.forEach(C => {
            this.setName(C, this.form.groupBy[2 - this.form.idxGroup]);
          });
        });
      });
    },
    setName(obj, type) {
      if (type === 'shop_id') {
        obj.name = `#${obj.id}`;
      } else if (type === 'brand_no') {
        let brand = this.brandMap[obj.id];
        if (brand) obj.name = `${brand.brand_no}. ${brand.brand_nm}`;
        else obj.name = `brand_no : ${obj.id}`;
      } else if (~['genderCategory', 'majorCategory', 'minorCategory', 'smallerCategory', 'category'].indexOf(type)) {
        let category = this.categoryMap[obj.id];
        if (category) obj.name = Array(obj.id.length / 3).fill(0).map((e, i) => this.categoryMap[obj.id.substring(0, 3 + i * 3)].category_nm).join(' > ');
        else obj.name = `${obj.id}`;
        // obj.name = `${obj.id}`;
      } else if (type === 'all') {
        obj.name = '전체 주문';
      }
    },
    async down(type) {
      let cols = '판매일,판매수량,판매금액,주문수,실거래액,최대혜택기준실거래액,실거래액,상품번호,상품명,brand_no,브랜드,category,성별,2DEPTH,3DEPTH,4DEPTH,5DEPTH,Shop ID,구분,이미지'.split(',');
      down(this.goods, cols, cols,
        `BalaanOrderStat_${this.$utils.dt()}`, type, {types: {'상품링크': 'link'}});
    },
  }
}
</script>
